<template>
<div class="main_wrp">

    <div class="tabtop">
        <span :class="acindx==0?'on':''" @click="showList(0)">待提交</span>
        <span :class="acindx==1?'on':''" @click="showList(1)">有效订单</span>
        <span :class="acindx==2?'on':''" @click="showList(2)">待评价</span>
        <span :class="acindx==3?'on':''" @click="showList(3)">已提交</span>
    </div>

    <div class="card_list">
        <UserPad />

        <template v-if="plist.length == 0">
            <div class="no-data">
                抱歉，当前服务器未查询到相关数据！！！
            </div>
        </template>
        <template v-else>
            <div class="card_wrp" v-for="(item,index) in plist" :key="index">
                <router-link class="card_main" :to="'/insuranceApplyDetail/'+item.Apply_ID">
                    <div class="card_tit">
                        {{item.Insurance_PolicyName}}
                    </div>

                    <div>
                        <span>订单号: </span>{{item.Apply_Number}}
                    </div>
                    <div>
                        <span>提交时间: </span>{{item.Apply_ReceiveTime}}
                    </div>
                    <div>
                        <span>进度状态: </span>{{statusNow(item.Apply_Verify)}}
                    </div>
                    <div>
                        <span>服务人员: </span> {{item.Apply_LastCourierName||"未分配"}}
                    </div>
                </router-link>
                <button type="warn" v-if="item.Insurance_Status == 1&&item.Insurance_RefundRequest == 0" @click="moneyBack(item.Insurance_ID)">申请退款</button>
            </div>
            <a href="javascript:void(0);" class="more-data" @click="loadmore(acindx)">加载更多</a>
        </template>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            status: 0,
            acindx: 0,
            pageindex: 1,
            gindx: 0,
            types: [],
            plist: [],
            member: ''
        }
    },
    created() {
        this.status = this.acindx = this.$route.params.status;
        console.log('status:', this.$route.params.status)
        this.showList(this.$route.params.status)

    },

    methods: {
        statusNow(num) {
            switch (num) {
                case 0:
                    ;
                case 1:
                    return '待分配'
                    break;
                case 2:
                    return '联系上门'
                    break;
                case 3:
                    return '收件完成'
                    break;
                case 4:
                    return '理赔核算中'
                    break;
                case 5:
                    return '理赔完成'
                    break;
                case 8:
                    return '拒绝理赔'
                    break;
                case 9:
                    return '退款'
                    break;
                case 10:
                    return '终止服务'
                    break;
            }
        },
        moneyBack: function (id) {
            let that = this
            this.wpost({
                url: '/Ajax/Insurance/refundrequest',
                data: {
                    id: id,
                },
                succ: function (data) {
                    console.log("result:", data)

                }
            })
        },
        showList: function (id) {
            let that = this
            this.plist = []
            this.acindx = id
            this.pageindex = 1

            this.wpost({
                url: '/Ajax/Product/getapplylist',
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: that.pageindex
                },
                succ: function (data) {
                    // console.log("result:",data)
                    that.plist = data.list
                    that.member = data.member
                }
            })
        },
        loadmore: function (id) {
            let that = this
            // console.log('loadmore data')
            this.wpost({
                url: '/Ajax/Product/getapplylist',
                data: {
                    status: id,
                    pagesize: 20,
                    pageindex: that.pageindex += 1
                },
                succ: function (data) {
                    if (data.list.length > 0) {
                        that.plist = that.plist.concat(data.list)
                    } else {
						alert("暂无更多相关数据...")
                        that.pageindex -= 1
                    }

                }
            })
            // console.log("pageindex:", that.pageindex)
        },

    }
}
</script>

<style>
page {
    background: #F5F5F5;
}

.main_wrp {
    height: 100vh;
    position: relative;
}

.wtop {
    position: relative;
}

.wtop image {
    width: 100%;
}

.wtopIn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px
}

.wtips {
    position: absolute;
    top: 6px;
    right: 12px;
    color: #fff;
    font-size: 24rpx;
}

.userInfo {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    color: #fff;
}

.wimg {
    width: 70px;
    height: 70px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;

}

.wimg image {
    width: 70px;
    height: 70px;
}

.wtext {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.wtext span {
    font-size: 25rpx;
}

.warr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    color: #fff;
}

.top_head {
    height: 51px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f97a0a;
}

.card_wrp button {
    margin: 10px 5px;
}

.card_list {
    padding-top: 41px;
    padding-bottom: 60px;
    height: 100vh;
    box-sizing: border-box;
}

.tabtop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #fff;
}

.tabtop span {
    display: block;
    width: 25%;
    color: #3F536E;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
    border: none;
    background-color: none;
}

.tabtop span.on {
    color: #F97A0A;
}

.tabtop span:active {
    background-color: #ECECEC;
}

.card_intros {
    font-size: 32rpx;
    line-height: 1.6;
    padding: 10px;
    font-weight: bold;
}

.card_wrp {
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 6px;
}

.card_tit {
    color: #333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 15px;
    margin: 6px 0;
}

.card_main {
    font-size: 14px;

}

.card_main div {
    margin-bottom: 8px;
}

.card_main span {
    margin-right: 6px;
    font-size: 13px;
    color: #F0AD4E;
}

.nav_county {
    width: 150upx;
    text-align: center;
    border-right: 1px solid #DEDEDE;
    box-sizing: border-box;
}

.nav_county span {
    display: block;
    padding: 12px 0;
    font-size: 14px;
    background-color: #ececec;
    color: #888;
    border: 1px solid #dedede;
    border-top: none;
    border-right: none;
}

.nav_county span.ac {
    color: #f97a0a;
    background: #fff;
}
</style>
